import { render, staticRenderFns } from "./show.vue?vue&type=template&id=0b237e86&scoped=true&"
import script from "./show.vue?vue&type=script&lang=js&"
export * from "./show.vue?vue&type=script&lang=js&"
import style0 from "./show.vue?vue&type=style&index=0&id=0b237e86&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/_vue-loader@15.10.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0b237e86",
  null
  
)

export default component.exports