<template>
     <div class="resume">
        <div class="title">
            <div class="sub-title">消息详情</div>
            <!-- <div class="sub-filtter"><i class="el-icon-delete"></i> 清空索索条件</div> -->
        </div>
        <el-card class="box-card" style="min-height:100%">
            <div class="listbox">
                <div v-if="sjload" style="text-align:center;">数据加载中...</div>
                <ul>
                    <li v-for="(item,index) in list" :class="item.type==1?'send1':'send2'">
                        <div class="messitem">
                            <div class="messtime">{{item.type==1?item.userName:item.memberName}} - {{item.createTime}} </div>
                            <div class="messcont">
                                <span>{{item.message}}</span>
                            </div>
                        </div>
                    </li>
                    
                </ul>
                <div class="send">
                    <el-input @keyup.enter.native="saveSend" v-model="form.message" type="textarea" placeholder="请输入消息" :autosize="{ minRows: 2, maxRows: 4}"></el-input>
                    <div class="sendbox"><el-button type="primary" size="small" @click="saveSend">发送</el-button></div>
                </div>
            </div>
        </el-card>
     </div>
</template>
<script>
import companyRequest from '../../../api/company'
export default {
    data(){
        return{
            form:{message:null,userId:null,memberId:null,type:2},
            userId:0,
            memberId:0,
            list:[],
            sjload:true,
            timer:""
        }
    },
    created(){
        this.form.userId=this.$route.query.u
        this.form.memberId=this.$route.query.m
        this.getList()
    },
    mounted(){
        this.timer=setInterval(this.getList,3000)
    },
    beforeDestroy() {
      clearInterval(this.timer);
    },
    methods:{
        getList(){
            companyRequest.messageList({userId:this.form.userId,memberId:this.form.memberId,isMember:true}).then(res=>{
                //console.log(res)
                this.sjload=false
                this.list=res.data
            })
        },
        saveSend(){
            companyRequest.sendMessage(this.form).then(res=>{
                if(res.success){
                   console.log(res)
                   this.form.message=null
                }
            })
        },
    }
}
</script>
<style scoped>
.listbox{ width: 1198px; box-sizing: border-box; margin:0px auto; background: #ffffff; padding:30px;}
.messtime{ font-size:12px; color:#999; margin-bottom: 10px;}
.messcont{}
.listbox ul li{ padding-bottom: 25px;}
.messcont span{ display: inline-block; padding:8px 12px; line-height: 150%; color:#ffffff; border-radius: 10px; box-shadow: #f0f0f0 1px 2px 6px; }
.listbox ul li.send1 .messcont span{ background:#27a799;}
.listbox ul li.send2 .messtime{ text-align: right;}
.listbox ul li.send2 .messcont{ text-align: right;}
.listbox ul li.send2 .messcont span{ background:#ff8e42;}
.huifu{ color:#27a799 ;}
.send{ padding-top:35px;}
.sendbox{ text-align: right; padding-top:8px;}
</style>